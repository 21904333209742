import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../env.js";

function Registro() {
    //deconstruye el contenido de las variables
    const [passwordVisible, setPasswordVisible] = useState({
        id_password1: false,
        id_conf_password: false
    });
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [usuario, setUsuario] = useState('');
    const [pass, setPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const [correo, setCorreo] = useState('');
    const [terms, setTerms] = useState(false);

    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = 'My personal portfolio - ' + h1Text;;

        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));
        if (toggleCheckbox) {
            document.querySelector('#signIn-form').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('.main-form').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
            document.querySelectorAll('input').forEach(input => {
                input.style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            })
        } else {
            document.querySelector('#signIn-form').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.main-form').style.backgroundColor = 'var(--bg-container-color)';
            document.querySelectorAll('input').forEach(input => {
                input.style.backgroundColor = 'var(--input-color)';
            })
        }
    }, [])

    //toma el valor previo y lo cambia
    const mostrar = (fieldId) => {
        setPasswordVisible((prev) => ({
            ...prev,
            [fieldId]: !prev[fieldId]
        }));
    };

    const sendData = (e) => {
        e.preventDefault();

        var test = {
            nombre: false,
            apellidos: false,
            usuario: false,
            pass: false,
            confPass: false,
            correo: false,
            terms: false
        }

        if (!/^[A-Za-z]+(\s[A-Za-z]+)?$/.test(nombre)) {
            document.querySelector('#id_err_nombre').previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_nombre').classList.remove('error-inactivo');
            document.querySelector('#id_suc_nombre').classList.add('exito-inactivo');
            test.nombre = false;
        } else {
            document.querySelector('#id_err_nombre').previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_nombre').classList.add('error-inactivo');
            document.querySelector('#id_suc_nombre').classList.remove('exito-inactivo');
            test.nombre = true;
        }

        if (!/^[A-Za-z]+(\s[A-Za-z]+)?$/.test(apellidos)) {
            document.querySelector('#id_err_apellidos').previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_apellidos').classList.remove('error-inactivo');
            document.querySelector('#id_suc_apellidos').classList.add('exito-inactivo');
            test.apellidos = false;
        } else {
            document.querySelector('#id_err_apellidos').previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_apellidos').classList.add('error-inactivo');
            document.querySelector('#id_suc_apellidos').classList.remove('exito-inactivo');
            test.apellidos = true;
        }

        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(usuario)) {
            document.querySelector('#id_err_usuario').previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_usuario').classList.remove('error-inactivo');
            document.querySelector('#id_suc_usuario').classList.add('exito-inactivo');
            test.usuario = false;
        } else {
            document.querySelector('#id_err_usuario').previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_usuario').classList.add('error-inactivo');
            document.querySelector('#id_suc_usuario').classList.remove('exito-inactivo');
            test.usuario = true;
        }

        if (!/^(\w+)(\.\w+)?@([A-Za-z]+)(\.[A-Za-z]+)?\.([A-Za-z]{2,3})$/.test(correo)) {
            document.querySelector('#id_err_email').previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_email').classList.remove('error-inactivo');
            document.querySelector('#id_suc_email').classList.add('exito-inactivo');
            test.correo = false;
        } else {
            document.querySelector('#id_err_email').previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_email').classList.add('error-inactivo');
            document.querySelector('#id_suc_email').classList.remove('exito-inactivo');
            test.correo = true;
        }

        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(pass)) {
            document.querySelector('#id_err_pass').previousSibling.previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_pass').classList.remove('error-inactivo');
            document.querySelector('#id_err_pass').nextElementSibling.classList.remove('error-inactivo');
            document.querySelector('#id_suc_pass').classList.add('exito-inactivo');
            test.pass = false;
        } else {
            document.querySelector('#id_err_pass').previousSibling.previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_pass').classList.add('error-inactivo');
            document.querySelector('#id_err_pass').nextElementSibling.classList.add('error-inactivo');
            document.querySelector('#id_suc_pass').classList.remove('exito-inactivo');
            test.pass = true;
        }

        if (pass !== confPass) {
            document.querySelector('#id_err_confPass').previousSibling.previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_confPass').classList.remove('error-inactivo');
            document.querySelector('#id_suc_confPass').classList.add('exito-inactivo');
            test.confPass = false;
        } else {
            document.querySelector('#id_err_confPass').previousSibling.previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_confPass').classList.add('error-inactivo');
            document.querySelector('#id_suc_confPass').classList.remove('exito-inactivo');
            test.confPass = true;
        }

        if (!terms) {
            document.querySelector('#id_err_terms').previousSibling.previousSibling.style.border = '2px solid red';
            document.querySelector('#id_err_terms').classList.remove('error-inactivo');
            document.querySelector('#id_suc_terms').classList.add('exito-inactivo');
            test.terms = false;
        } else {
            document.querySelector('#id_err_terms').previousSibling.previousSibling.style.border = '2px solid var(--success)';
            document.querySelector('#id_err_terms').classList.add('error-inactivo');
            document.querySelector('#id_suc_terms').classList.remove('exito-inactivo');
            test.terms = true;
        }

        if (test.nombre && test.apellidos && test.usuario && test.pass && test.confPass && test.correo && test.terms) {
            try {
                const data = {
                    nombre: nombre,
                    apellidos: apellidos,
                    usuario: usuario,
                    pass: encodeURIComponent(pass),
                    correo: correo
                }

                var config = {
                    'Content-Type': 'application/json'
                }

                console.log(env);
                console.log(process.env);
                // Asegúrate de que NODE_ENV está configurado
                console.log('NODE_ENV:', process.env.NODE_ENV);

                axios.put('http://' + env.SERVER_HOST + ':5000/API/insertUsuarios.php', {
                    params: data,
                    headers: config
                }).then(response => {
                    if (response.data.status === 'success') {
                        window.location.href = '/sesion';
                    }
                }).catch(error => {
                    console.error(error);
                    alert('El usuario ya existe');
                })
            } catch (error) {
                console.error('Error en el envio');
            }
        }
    }

    return (
        <div className="main-form">
            <h1 className="hide">Registro</h1>
            <form id="signIn-form" onSubmit={sendData}>
                <h1>REGISTRO</h1>
                <br />
                <div className="form-body">
                    <div className="grp grp-1">
                        <div className="input-group">
                            <label htmlFor="nombre">Nombre:</label>
                            <input type="text" name="nombre" id="id_nombre" placeholder="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                            <p className="error error-inactivo" id="id_err_nombre"><i className="bi bi-exclamation-circle-fill"></i> Campo invalido</p>
                            <p className="success exito-inactivo" id="id_suc_nombre"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                        <div className="input-group">
                            <label htmlFor="apellidos">Apellidos:</label>
                            <input type="text" name="apellidos" id="id_apellidos" placeholder="Apellidos" value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                            <p className="error error-inactivo" id="id_err_apellidos"><i className="bi bi-exclamation-circle-fill"></i> Campo invalido</p>
                            <p className="success exito-inactivo" id="id_suc_apellidos"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                    </div>
                    <div className="grp grp-2">
                        <div className="input-group">
                            <label htmlFor="Usuario">Usuario:</label>
                            <input type="text" name="usuario" id="id_usuario" placeholder="Nombre de usuario" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                            <p className="error error-inactivo" id="id_err_usuario"><i className="bi bi-exclamation-circle-fill"></i> El nombre de usuario debe de tener al menos 1 número</p>
                            <p className="success exito-inactivo" id="id_suc_usuario"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Correo electronico:</label>
                            <input type="email" name="email" id="id_email" placeholder="example@email.com" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                            <p className="error error-inactivo" id="id_err_email"><i className="bi bi-exclamation-circle-fill"></i> Pon una dirección de correo valida</p>
                            <p className="success exito-inactivo" id="id_suc_email"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                    </div>
                    <div className="grp grp-3">
                        <div className="input-group wrap-input">
                            <label htmlFor="password">Contraseña:</label>
                            {/*cambia el estado del input y del ojo dependiendo del valor que se recoge en la variable */}
                            <input type={passwordVisible.id_password1 ? 'text' : 'password'} name="password" id="id_password1" placeholder="Password" value={pass} onChange={(e) => setPass(e.target.value)} />
                            <i className={`bi ${passwordVisible.id_password1 ? 'bi-eye-slash' : 'bi-eye'}`} id='ojo1' onClick={() => mostrar('id_password1')}></i>
                            <p className="error error-inactivo" id="id_err_pass"><i className="bi bi-exclamation-circle-fill"></i> La contraseña debe tener:</p>
                            <ul className="error error-inactivo">
                                <li>Al menos 8 caracteres</li>
                                <li>1 mayúscula</li>
                                <li>1 minúscula</li>
                                <li>1 número</li>
                                <li>1 simbolo de estos %#&$-_</li>
                            </ul>
                            <p className="success exito-inactivo" id="id_suc_pass"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                        <div className="input-group wrap-input">
                            <label htmlFor="conf_password">Confirmar contraseña:</label>
                            <input type={passwordVisible.id_conf_password ? 'text' : 'password'} name="conf_password" id="id_conf_password" placeholder="Password" value={confPass} onChange={(e) => setConfPass(e.target.value)} />
                            <i className={`bi ${passwordVisible.id_conf_password ? 'bi-eye-slash' : 'bi-eye'}`} id='ojo2' onClick={() => mostrar('id_conf_password')}></i>
                            <p className="error error-inactivo" id="id_err_confPass"><i className="bi bi-exclamation-circle-fill"></i> Las contraseñas deben coincidir</p>
                            <p className="success exito-inactivo" id="id_suc_confPass"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                        </div>
                    </div>
                </div>
                <br />
                <div className="signIn-footer">
                    <div className="terms">
                        <input type="checkbox" name="terms" id="id_chk_terms" checked={terms} onChange={(e) => setTerms(e.target.checked)} />
                        <label htmlFor="terms">Acepto los términos</label>
                        <p className="error error-inactivo" id="id_err_terms"><i className="bi bi-exclamation-circle-fill"></i> Acepta los términos y condiciones</p>
                        <p className="success exito-inactivo" id="id_suc_terms"><i className="bi bi-check-circle-fill"></i> Campo valido</p>
                    </div>
                    <input type="submit" name="enviar" id="id_enviar" value='REGISTRARSE' />
                    <div>
                        <p><b>¿Ya registrado?</b></p>
                        <a href="/sesion">Iniciar sesion</a>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default Registro;