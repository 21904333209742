import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//estilos
import './layout/css/index.css';
import './layout/css/sesion.css';
import './layout/css/main.css';
import './layout/css/anadir.css';
import './layout/css/mediaQuery.css';
import './layout/css/keyframes.css';
import './layout/css/perfil.css';
import './layout/css/editar.css';

//componentes
import Header from './components/Header';
import Sesion from './pages/sesion';
import Registro from './pages/registro';
import Main from './pages/main';
import Anadir from "./pages/anadir";
import Editar from "./pages/editar";
import Perfil from "./pages/perfil";
import Error404 from "./components/Error404";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route element={<Sesion/>} path="/sesion"/>
        <Route element={<Sesion/>} path="/"/>
        <Route element={<Registro/>} path="/registro"/>
        <Route element={<Main/>} path="/menu-principal"/>
        <Route element={<Anadir/>} path="/anadir"/>
        <Route element={<Editar/>} path="/editar"/>
        <Route element={<Perfil/>} path="/perfil"/>
        <Route element={<Error404 />} path="*"/>
      </Routes>
    </Router>
  );
}

export default App;