import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import env from '../env';

const ImageUploaderWithCrop = () => {
  const [ruta, setRuta] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('infousu'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('http://'+env.SERVER_HOST+':5000/API/getInfousu.php', { id_usuario: userInfo.id_usuario }, { 'Content-Type': 'application/json' });
        console.log(response.data.data[0].img);
        setRuta(response.data.data[0].img);
      } catch (error) {
        console.error('Error al obtener la información del usuario:', error);
      }
    };

    fetchData();
  }, [userInfo.id_usuario]);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('image', file);

    axios.post('http://'+env.SERVER_HOST+':5000/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.status === 'success') {
          const rutaImagen = response.data.data;
          const nuevaRutaImagen = rutaImagen.substring(rutaImagen.indexOf('public') + 'public'.length).replaceAll("\\", "/");
          /* console.log(nuevaRutaImagen); */
          if (userInfo && userInfo.id_usuario) {
            axios.put('http://'+env.SERVER_HOST+':5000/API/insertaPerfil.php', { id_usuario: userInfo.id_usuario, ruta: nuevaRutaImagen }, { headers: { 'Content-Type': 'application/json' } })
              .then(response => {
                if (response.data.status === 'success') {
                  console.log('Foto actualizada con éxito.');
                  setRuta(nuevaRutaImagen);
                  var datosusu = JSON.parse(localStorage.getItem('infousu'));
                  console.log(datosusu);
                } else {
                  console.error('Error al actualizar la foto de perfil:', response.data.message);
                }
              })
              .catch(error => {
                console.error('Error en la solicitud PUT para actualizar la foto de perfil:', error);
              });
          } else {
            console.error('Información del usuario no encontrada en localStorage.');
          }
        } else {
          console.error('Error en la carga de la imagen:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error en la carga de la imagen:', error);
      });
  };

  const cropStyles = {
    border: '2px dashed black',
    borderRadius: '50px',
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div>
      <Dropzone onDrop={handleDrop} accept={{ "image/*": [".png", ".jpeg", ".jpg"] }} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} style={cropStyles}>
              <input {...getInputProps()} />
              <img src={ruta || '/img/perfil.svg'} alt='profile' id='perfilImg' />
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImageUploaderWithCrop;