import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from "react-router-dom";

function Header() {
    const [isProfileOpen, setProfileOpen] = useState(false);
    const [anchura, setAnchura] = useState(window.innerWidth);
    const [logo, setLogo] = useState('');
    const menuRef = useRef();
    const location = useLocation();

    const handleProfileClick = (event) => {
        event.stopPropagation();
        setProfileOpen(!isProfileOpen);
    };

    const closeProfile = () => {
        setProfileOpen(false);
    };

    const closeSession = () => {
        if (localStorage.getItem('infousu')) {
            localStorage.removeItem('infousu');
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setAnchura(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (anchura < 450) {
            setLogo('/img/icono.svg');
        } else {
            setLogo('/img/logo.png');
        }
    }, [anchura, location]);
    

    useEffect(() => {
        closeProfile();
    }, [location]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeProfile();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className='header'>
            <img 
                src={logo}
                alt="logo del sitio" 
                onClick={() => {window.location.href = '/menu-principal'}} 
                onMouseOver={() => { document.body.style.cursor = 'pointer' }} 
                onMouseOut={() => { document.body.style.cursor = 'unset' }}
                title='Ir a menu principal'
            />
            <div>
                <i className="bi bi-person-circle" onClick={handleProfileClick}></i>
                <div ref={menuRef} className='header-hide-menu' style={{ display: isProfileOpen ? 'flex' : 'none' }}>
                    <NavLink to="/perfil">Mi perfil </NavLink>
                    <NavLink to="/editar">Mis portfolios</NavLink>
                    {localStorage.getItem('infousu') && <NavLink to="/menu-principal">Menú principal</NavLink>}
                    <NavLink to="/registro">Registro </NavLink>
                </div>
                <a href="/sesion" onClick={localStorage.getItem('infousu') ? closeSession : null}>
                    {localStorage.getItem('infousu') ? 'Cerrar sesión' : 'Iniciar sesión'}
                </a>
            </div>
        </div>
    )
}

export default Header;