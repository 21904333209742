import React, { useEffect, useState } from "react";
import axios from "axios";
import ProfilePictureUploader from "../components/Dropzone";
import env from "../env";

function Perfil() {
    const [modoOscuro, setModoOscuro] = useState(false);

    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = 'My personal portfolio - ' + h1Text;;

        toggleModoOscuro();
    }, []);

    useEffect(() => {
        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));
        if (modoOscuro || toggleCheckbox) {
            document.querySelector('.main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('aside').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkAside;
            document.querySelector('.container-main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
            document.querySelectorAll('p').forEach(input => {
                input.style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            })
        } else {
            document.querySelector('.main').style.backgroundColor = 'var(--container-color)';
            document.querySelector('aside').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.container-main').style.backgroundColor = 'var(--bg-container-color)';
            document.querySelectorAll('p').forEach(input => {
                input.style.backgroundColor = 'var(--input-color)';
            })
        }
    }, [modoOscuro]);

    function toggleModoOscuro() {
        const checkbox = document.getElementById("toggle-checkbox");
        checkbox.checked = !checkbox.checked;
        localStorage.setItem('checked', checkbox.checked);
        setModoOscuro(JSON.parse(localStorage.getItem('checked')));

        const valoresDarkMode = {
            darkSubContainerColor: 'var(--bg-container-color)',
            darkContainerColor: 'var(--bg-color-menu-hover)',
            darkPElement: 'var(--dark-input-color)',
            darkAside: 'var(--bg-container-color)'
        }

        if (checkbox.checked) {
            localStorage.setItem('modoOscuro', JSON.stringify(valoresDarkMode));
            console.log('modo oscuro');
        } else {
            localStorage.removeItem('modoOscuro');
            console.log('modo claro');
        }
    }

    const toggleAside = (e) => {
        var aside = document.querySelector('aside');

        if (e.target.nextElementSibling == null) {
            if (aside.style.left === '') {
                aside.style.left = '0px';
            } else {
                aside.style.left = '';
            }
        }
    }

    const mostrarModal = () => {
        const modalContent = `
            <div class="modal-bg">
                <div class="modal">
                    <h1>Atención</h1>
                    <i class="bi bi-exclamation-triangle"></i>
                    <span>Estás a punto de borrar la cuenta</span>
                    <span>¿Seguro que quieres continuar?</span>
                    <div>
                        <button id="btn-si">SI</button>
                        <button id="btn-no">NO</button>
                    </div>
                </div>
            </div>
        `;
        const containerMain = document.querySelector('.container-main');
        containerMain.insertAdjacentHTML('beforeend', modalContent);

        const btnSi = document.getElementById("btn-si");
        const btnNo = document.getElementById("btn-no");

        btnSi.addEventListener("click", deleteAccount);
        btnNo.addEventListener("click", () => {
            const modalBg = document.querySelector('.modal-bg');
            modalBg.remove();
        });
    };


    const deleteAccount = (e) => {
        try {
            var data = {
                id_usuario: JSON.parse(localStorage.getItem('infousu')).id_usuario
            };

            var config = {
                'Content-Type': 'application/json'
            }

            axios.post('http://'+env.SERVER_HOST+':5000/API/deleteUsuarios.php', {
                params: data,
                headers: config
            }).then(response => {
                if (response.data.status === 'success') {
                    localStorage.removeItem('infousu');
                    window.location.href = '/registro';
                } else {
                    alert(response.data.status);
                }
            }).catch(error => {
                console.error(error);
            });


        } catch (error) {
            console.error(error);
        }
    }

    return localStorage.getItem('infousu') ? (
        <div className="container-main perfil">
            <div className="main">
                <h1 className="hide">Perfil</h1>

                <div className="content">
                    <button className="toggle-aside" onClick={(e) => toggleAside(e)}><i className="bi bi-list"></i></button>
                    <aside>
                        <button className="toggle-aside" onClick={(e) => toggleAside(e)}><i className="bi bi-x"></i></button>
                        <ProfilePictureUploader />
                        <span>{JSON.parse(localStorage.getItem('infousu')).usuario}</span>
                        <a href="#info-per">Información personal</a>
                        <a href="#configuracion">Configuración</a>
                    </aside>
                    <main>
                        <div id="info-per">
                            <h1>Mi perfil</h1>
                            <label htmlFor="usuario">Nombre de usuario:</label>
                            <p>{JSON.parse(localStorage.getItem('infousu')).usuario}</p>
                            <br />
                            <label htmlFor="correo">Correo electrónico:</label>
                            <p>{JSON.parse(localStorage.getItem('infousu')).correo}</p>
                            <br />
                            <label htmlFor="nombre">Nombre:</label>
                            <p>{JSON.parse(localStorage.getItem('infousu')).nombre}</p>
                            <br />
                            <label htmlFor="apellidos">Apellidos:</label>
                            <p>{JSON.parse(localStorage.getItem('infousu')).apellidos}</p>
                        </div>
                        <div id="configuracion">
                            <div className="grp-conf">
                                <label htmlFor="">Modo oscuro</label>
                                <div className="toggle-btn" onClick={toggleModoOscuro}>
                                    <input type="checkbox" id="toggle-checkbox" checked={JSON.parse(localStorage.getItem('checked')) ? JSON.parse(localStorage.getItem('checked')) : false} onChange={(e) => setModoOscuro(JSON.parse(localStorage.getItem('checked')))} />
                                    <span className="slider"></span>
                                </div>
                            </div>
                            <button id="btn-borrarCuenta" onClick={(e) => mostrarModal(e)}>borrar cuenta</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    ) : (
        window.location.href = '/sesion'
    )
}

export default Perfil;