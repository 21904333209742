import React, { useEffect } from 'react';

function Main() {
    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = 'My personal portfolio - ' + h1Text;;

        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));
        if (toggleCheckbox) {
            document.querySelector('.main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('.container-main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
            document.querySelectorAll('p').forEach(input => {
                input.style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            })
        } else {
            document.querySelector('.main').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.container-main').style.backgroundColor = 'var(--bg-container-color)';
            document.querySelectorAll('p').forEach(input => {
                input.style.backgroundColor = 'var(--input-color)';
            })
        }
    }, []);

    return localStorage.getItem('infousu') ? (
        <div className='container-main'>
            <div className='main'>
                <h1 className='hide'>Menú principal</h1>
                <h1>Hola {JSON.parse(localStorage.getItem('infousu')).usuario}, ¿Qué quieres hacer?</h1>

                <div className='action-btns'>
                    <a href='/anadir'>
                        <i className='bi bi-plus'></i>
                        Nueva plantilla
                    </a>
                    <a href='/editar'>
                        <i className="bi bi-pencil-square"></i>
                        Editar plantilla
                    </a>
                </div>
            </div>
        </div>
    ) : (
        (() => {
            window.location.href = '/sesion';
        })()
    );
}

export default Main;