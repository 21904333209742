import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../env";

function Anadir() {
    const [estudios, setEstudios] = useState([]);
    const [trabajos, setTrabajos] = useState([]);
    const [nombre, setNombre] = useState('');
    const [fecNac, setFecNac] = useState('');
    const [hobbies, setHobbies] = useState([]);
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [hobbiesValue, setHobbiesValue] = useState('');
    const [descripcion, setDescripcion] = useState('');


    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = h1Text + ' - My personal portfolio';

        //limpiar la informacion del portfolio anterior
        if (!localStorage.getItem('editar')) {
            localStorage.removeItem('infoPortfolio');
        }

        const storedInfo = JSON.parse(localStorage.getItem('infoPortfolio'));
        if (storedInfo) {
            // Establecer los valores iniciales de los estados locales
            setNombre(storedInfo.nombre || '');
            setHobbies(storedInfo.hobbies || []);
            setHobbiesValue((storedInfo.hobbies || []).map(hobby => hobby.descripcion).join('\n'));
            setFecNac(storedInfo.fecNac || '');
            setEstudios(storedInfo.estudios || []);
            setTrabajos(storedInfo.trabajos || []);
            setDescripcion(storedInfo.descripcion || '');
            setCorreo(storedInfo.correo || '');
            setTelefono(storedInfo.telefono || '');
        }
    }, []);

    useEffect(() => {
        //Manejador del modo oscuro
        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));

        if (toggleCheckbox) {
            document.querySelector('.main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('.container-main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
        } else {
            document.querySelector('.main').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.container-main').style.backgroundColor = 'var(--bg-container-color)';
        }

    }, [estudios, trabajos, hobbies, nombre, fecNac]);

    useEffect(() => {
        if (localStorage.getItem('editar')) {
            const infoPortfolio = JSON.parse(localStorage.getItem('infoPortfolio'));
            if (infoPortfolio && infoPortfolio.hobbies.length > 0) {
                localStorage.setItem('id_infoPer', infoPortfolio.hobbies[0].id_infoPer);
            } else {
                const data = {
                    id_portfolio: JSON.parse(localStorage.getItem('id_portfolio'))
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('http://'+env.SERVER_HOST+':5000/API/getidInfoPer.php', data, config).then(response => {
                    localStorage.setItem('id_infoPer', response.data.data[0].id_infoPer)
                }).catch(error => {
                    console.error(error)
                });
            }
        }
    }, [hobbies]);

    const sendData = async (e) => {
        e.preventDefault();

        let semaforo = true;
        var anyos = [];
        var dates = [];

        var elementos = document.forms[0].elements;
        Array.from(elementos).forEach(elem => {
            if (elem.getAttribute('type') !== 'submit' && elem.tagName !== 'BUTTON') {
                var errorElement = elem.parentElement.querySelector('.error');
                var noErrorElement = elem.parentElement.querySelector('.success');
                var isYearField = elem.name.toLowerCase().includes('año');
                var isDateField = elem.name.toLowerCase().includes('fecha');
                var isTelField = elem.name.toLowerCase().includes('telefono');
                var isEmailField = elem.name.toLowerCase().includes('emailusu');

                //comprueba que los campos esten rellenados
                var emailRegex = /^(\w+)(\.\w+)?@(\w+)\.[a-zA-Z]{2,}$/;
                var phoneRegex = /^\d{9}$/;

                // Comprueba que los campos estén rellenados y validados
                var isValid = isYearField ? /^\d{4}$/.test(elem.value) :
                    isEmailField ? emailRegex.test(elem.value) :
                        isTelField ? phoneRegex.test(elem.value) :
                            !/^\s*$/.test(elem.value);

                //si las fechas están mal, el formulario no será valido y por tanto no se envian los datos
                isDateField && dates.push(elem.value);
                if (dates[0] > dates[1]) {
                    alert('La fecha de inicio debe ser menor que la de fin');
                    isValid = false;
                }

                isYearField && anyos.push(elem.value);
                if (anyos[0] > anyos[1]) {
                    alert('El año de inicio debe ser menor que el de fin');
                    isValid = false
                }

                var borderColor = isValid ? 'var(--success)' : 'var(--error)';
                var message = isValid ? '<i class="bi bi-check-circle-fill"></i> Campo correcto' : '<i class="bi bi-exclamation-circle-fill"></i> Completa el campo';
                var messageClass = isValid ? 'success' : 'error';

                elem.style.border = `1px solid ${borderColor}`;

                if (errorElement) errorElement.remove();
                if (noErrorElement) noErrorElement.remove();

                var newMessage = document.createElement('p');
                newMessage.className = messageClass;
                newMessage.innerHTML = message;
                elem.parentNode.appendChild(newMessage);

                if (!isValid) {
                    semaforo = false;
                }
            }
        });

        if (semaforo) {
            try {
                const data = {
                    infoPort: infoPort,
                    id_usuario: encodeURIComponent(JSON.parse(localStorage.getItem('infousu')).id_usuario),
                    id_portfolio: parseInt(localStorage.getItem('id_portfolio'))
                };
                /* console.log(data); */
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                if (!localStorage.getItem('infoPortfolio')) {
                    console.log('insertando');
                    //añade un nuevo portfolio
                    await axios.put('http://'+env.SERVER_HOST+':5000/API/insertInfo.php', {
                        params: data,
                        headers: config
                    }).then(response => {
                        if (response.data.status === 'success') {
                            window.location.href = '/menu-principal';
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                } else {
                    console.log('actualizando');
                    await axios.put('http://'+env.SERVER_HOST+':5000/API/updatePortfolio.php', {
                        params: data,
                        headers: config
                    }).then(response => {
                        if (response.data.status === 'success') {
                            window.location.href = '/menu-principal';
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (localStorage.getItem('editar')) {
            localStorage.removeItem('editar');
            localStorage.removeItem('id_infoPer');
        }
    }

    const hobbiesFunc = (e) => {
        const value = e.target.value;
        setHobbiesValue(value);

        // Convertir el texto separado por saltos de línea en un array de hobbies
        const hobbiesArray = value.split('\n').filter(hobby => hobby.trim() !== '');

        const newHobbies = hobbiesArray.map((hobby, index) => {
            console.log(hobbies);
            
            const idToUse = hobbies[index] ? hobbies[index]?.id_hobUsu : null; // Obtener el ID correspondiente del estado hobbies
            const idInfoPer = JSON.parse(localStorage.getItem('id_infoPer'));
            console.log(idInfoPer);
            return {
                id_hobUsu: idToUse,
                descripcion: hobby,
                id_infoPer: idInfoPer
            };
        });

        return newHobbies;
    }

    const handleHobbiesChange = (e) => {
        const newHobbies = hobbiesFunc(e);
        setHobbies(newHobbies);
        console.log(hobbiesFunc(e));
    }

    var infoPort =
    {
        nombre: nombre,
        hobbies: hobbies,
        fecNac: fecNac,
        correo: correo,
        telefono: telefono,
        descripcion: descripcion,
        estudios: estudios,
        trabajos: trabajos
    }

    const newFormEstudios = (e) => {
        e.preventDefault();
        const newEstudios = [...estudios, { id: 0, titulo: '', centro: '', fechaInicio: '', fechaFin: '' }];
        setEstudios(newEstudios);
    }

    const newFormTrabajos = (e) => {
        e.preventDefault();
        const newTrabajos = [...trabajos, { id: 0, puesto: '', empresa: '', añoInicio: '', añoFin: '' }];
        setTrabajos(newTrabajos);
    }

    const handleNewInputChangeEstudios = (index, event) => {
        const { name, value } = event.target;
        const newEstudios = [...estudios];
        newEstudios[index] = { ...newEstudios[index], [name]: value };
        setEstudios(newEstudios);
    };

    const handleNewInputChangeTrabajos = (index, event) => {
        const { name, value } = event.target;
        const newTrabajos = [...trabajos];
        newTrabajos[index] = { ...newTrabajos[index], [name]: value };
        setTrabajos(newTrabajos);
    };

    const removeFormEstudios = (index) => {
        const newEstudios = [...estudios];
        const estudioToDelete = newEstudios[index];
        newEstudios.splice(index, 1);
        setEstudios(newEstudios);

        // Realizar la solicitud HTTP para eliminar el estudio del servidor si tiene un ID válido
        if (estudioToDelete.id) {
            try {
                const data = { id_estudio: estudioToDelete.id };
                const config = { headers: { 'Content-Type': 'application/json' } };

                axios.post('http://'+env.SERVER_HOST+':5000/API/deleteEstudio.php', data, config)
                    .then(response => {
                        if (response.data.status === 'success') {
                            console.log('Estudio borrado exitosamente');
                        } else {
                            console.error('Error al borrar el estudio');
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud HTTP:', error);
                    });
            } catch (error) {
                console.error('Error en la solicitud HTTP:', error);
            }
        }
    };

    const removeFormTrabajos = (index) => {
        const newTrabajos = [...trabajos];
        const trabajoToDelete = newTrabajos[index];
        newTrabajos.splice(index, 1);
        setTrabajos(newTrabajos);

        if (trabajoToDelete.id) {
            try {
                const data = { id_trabajo: trabajoToDelete.id };
                const config = { headers: { 'Content-Type': 'application/json' } };

                axios.post('http://'+env.SERVER_HOST+':5000/API/deleteTrabajo.php', data, config)
                    .then(response => {
                        if (response.data.status === 'success') {
                            console.log('Trabajo borrado exitosamente');
                        } else {
                            console.error('Error al borrar el trabajo');
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud HTTP:', error);
                    });
            } catch (error) {
                console.error('Error en la solicitud HTTP:', error);
            }
        }
    };

    const actionsReturn = (e) => {
        e.preventDefault();
        if (localStorage.getItem('editar')) {
            localStorage.removeItem('editar');
            localStorage.removeItem('id_infoPer');
        }
        window.location.href = '/menu-principal';
    }

    return localStorage.getItem('infousu') ? (
        <div className="container-main">
            <div className="main anadir">
                <h1 className="hide">Añadir</h1>
                <h1>Nueva plantilla</h1>
                <form className="div-container" onSubmit={(e) => sendData(e)}>
                    <div className="div-container-columns">
                        <div className="div-container-izq">
                            <h2>Información personal</h2>
                            <div className="input-group">
                                <label htmlFor="nombre">Nombre:</label>
                                <input type="text" name="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="Portfolio de Pepe" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="hobbies">Hobbies:</label>
                                <textarea name="hobbies" value={hobbiesValue} onChange={(e) => handleHobbiesChange(e)} placeholder="Hobbies" style={{ resize: 'none' }}></textarea>
                                <span className="info"><i className="bi bi-exclamation-circle-fill"></i>Los hobbies se deben separar con saltos de linea</span>
                            </div>
                            <div className="input-group">
                                <label htmlFor="fecNac">Fecha de nacimiento:</label>
                                <input type="date" name="fecNac" value={fecNac} onChange={(e) => setFecNac(e.target.value)} placeholder="DD/MM/AAAA" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="emailUsu">Correo electronico:</label>
                                <input type="email" name="emailUsu" value={correo} onChange={(e) => setCorreo(e.target.value)} placeholder="correo electrónico" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="telefono">Teléfono:</label>
                                <input type="text" name="telefono" value={telefono} onChange={(e) => setTelefono(e.target.value)} placeholder="XXXXXXXXX" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="presentacion">Carta de presentación:</label>
                                <textarea name="presentacion" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} placeholder="Describete" style={{ resize: 'none' }}></textarea>
                            </div>
                        </div>
                        <div className="div-container-der">
                            <h2>Estudios</h2>
                        
                            {estudios.map((estudio, index) => {
                                return (
                                    <div key={index} id={estudio.id} className="input-group-container">
                                        <span className="quit" onClick={() => removeFormEstudios(index)}>X</span>
                                        <div className="grp grp-1">
                                            <div className="input-group">
                                                <label htmlFor='titulo'>Titulo:</label>
                                                <input type="text" name='titulo' value={estudio.titulo} onChange={(e) => handleNewInputChangeEstudios(index, e)} placeholder="Titulo de estudio" />
                                            </div>
                                            <div className="input-group">
                                                <label htmlFor='centro'>Centro de estudios</label>
                                                <input type="text" name='centro' value={estudio.centro} onChange={(e) => handleNewInputChangeEstudios(index, e)} placeholder="Centro de estudios" />
                                            </div>
                                        </div>
                                        <div className="grp grp-2">
                                            <div className="input-group">
                                                <label htmlFor='fechaInicio'>Fecha de inicio:</label>
                                                <input type="date" name='fechaInicio' value={estudio.fechaInicio} onChange={(e) => handleNewInputChangeEstudios(index, e)} placeholder="DD/MM/AAAA" />
                                            </div>
                                            <div className="input-group">
                                                <label htmlFor='fechaFin'>Fecha de fin</label>
                                                <input type="date" name='fechaFin' value={estudio.fechaFin} onChange={(e) => handleNewInputChangeEstudios(index, e)} placeholder="DD/MM/AAAA" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <button className="btn-add-estudio" onClick={newFormEstudios}><i className="bi bi-plus"></i></button>
                        
                            <br />
                            <h2>Vida laboral</h2>
                            {trabajos.map((trabajo, index) => (
                                <div key={index} id={trabajo.id} className="input-group-container">
                                    <span className="quit" onClick={() => removeFormTrabajos(index)}>X</span>
                                    <div className="grp grp-1">
                                        <div className="input-group">
                                            <label htmlFor='puesto'>Puesto:</label>
                                            <input type="text" name='puesto' value={trabajo.puesto} onChange={(e) => handleNewInputChangeTrabajos(index, e)} placeholder="Puesto" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor='empresa'>Empresa:</label>
                                            <input type="text" name='empresa' value={trabajo.empresa} onChange={(e) => handleNewInputChangeTrabajos(index, e)} placeholder="Centro de trabajos" />
                                        </div>
                                    </div>
                                    <div className="grp grp-2">
                                        <div className="input-group">
                                            <label htmlFor='añoInicio'>Año de inicio:</label>
                                            <input type="text" name='añoInicio' value={trabajo.añoInicio} onChange={(e) => handleNewInputChangeTrabajos(index, e)} placeholder="AAAA" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor='añoFin'>Fecha de fin</label>
                                            <input type="text" name='añoFin' value={trabajo.añoFin} onChange={(e) => handleNewInputChangeTrabajos(index, e)} placeholder="AAAA" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button className="btn-add-estudio" onClick={newFormTrabajos}><i className="bi bi-plus"></i></button>
                        </div>
                    </div>
                    <div className="action-btns-form">
                        <button onClick={(e) => actionsReturn(e)}><span><i className="bi bi-chevron-left"></i>Volver</span></button>
                        <input type="submit" value="Añadir" />
                    </div>
                </form>
            </div>
        </div>
    ) : (
        window.location.href = '/sesion'
    )
}

export default Anadir;