import axios from "axios";
import React, { useEffect, useState } from "react";
import html2pdf from 'html2pdf.js';
import env from "../env";

function Editar() {
    const [portfolio, setPortfolio] = useState([]);

    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = 'My personal portfolio - ' + h1Text;;

        try {
            var data = {
                id_usuario: JSON.parse(localStorage.getItem('infousu')).id_usuario
            }

            var config = {
                'Content-Type': 'application/json'
            }

            axios.post('http://'+env.SERVER_HOST+':5000/API/getPortfolios.php', {
                params: data,
                headers: config
            }).then(response => {
                setPortfolio(response.data.data);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));
        if (toggleCheckbox) {
            document.querySelector('.main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('.container-main').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
            if (document.querySelector('#ctxMenu')) {
                document.querySelector('#ctxMenu').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            }
            document.querySelectorAll('.portfolio-div').forEach(element => {
                element.style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            });
        } else {
            document.querySelector('.main').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.container-main').style.backgroundColor = 'var(--bg-container-color)';
            if (document.querySelector('#ctxMenu')) {
                document.querySelector('#ctxMenu').style.backgroundColor = 'var(--ctxMenu-bg-color)';
            }
            document.querySelectorAll('.portfolio-div').forEach(element => {
                element.style.backgroundColor = 'var(--portfolio-color)';
            });
        }
    }, [portfolio])

    const handleContextMenu = (e) => {
        e.preventDefault();

        const ctxMenuId = 'ctxMenu';
        const existingCtxMenu = document.getElementById(ctxMenuId);
        if (existingCtxMenu) {
            existingCtxMenu.parentNode.removeChild(existingCtxMenu);
        }

        const ctxMenu = document.createElement('div');
        ctxMenu.id = ctxMenuId;
        ctxMenu.style.width = '150px';
        ctxMenu.style.backgroundColor = 'var(--ctxMenu-bg-color)';
        ctxMenu.style.position = 'absolute';
        ctxMenu.style.left = `${e.clientX}px`;
        ctxMenu.style.top = `${e.clientY}px`;

        ctxMenu.innerHTML = `
        <ul>
        <li id="deletePortfolio">Borrar <i class="bi bi-trash"></i></li>
        <li id="updatePortfolio">Editar <i class="bi bi-pencil"></i></li>
        <li id="download"><span id="pdf-generator-span">Descargar PDF<i class='bi bi-filetype-pdf'></i></span></li>
        </ul>
        `;

        document.body.appendChild(ctxMenu);

        const deletePortfolioBtn = ctxMenu.querySelector('#deletePortfolio');
        deletePortfolioBtn.addEventListener('click', () => mostrarModal(e));

        const updatePortfolioBtn = ctxMenu.querySelector('#updatePortfolio');
        updatePortfolioBtn.addEventListener('click', () => updatePortfolio(e));

        const downloadPDFBtn = ctxMenu.querySelector('#download');
        downloadPDFBtn.addEventListener('click', () => handleDownloadClick(e));

        document.addEventListener('click', (event) => {
            if (ctxMenu && document.body.contains(ctxMenu)) {
                document.body.removeChild(ctxMenu);
            }
        });
    }

    const handleDownloadClick = (e) => {
        console.log('Recopilando información');

        try {
            localStorage.setItem('id_portfolio', e.target.id);

            var data = {
                id_portfolio: JSON.parse(localStorage.getItem('id_portfolio'))
            }

            var config = {
                'Content-Type': 'application/json'
            }

            //Hacemos la petición al servidor para recoger la información de cada portfolio
            axios.post('http://'+env.SERVER_HOST+':5000/API/getInfoPortfolio.php', {
                params: data,
                headers: config
            }).then(response => {
                if (response.data.status === 'success') {
                    var datos = response.data.data;

                    var imagen = JSON.parse(localStorage.getItem('infousu')).img;
                    var nombre = datos[0].informacion.split(',')[0].trim();
                    var fecNac = datos[0].informacion.split(',')[1].trim();
                    var correo = datos[0].informacion.split(',')[2].trim();
                    var telefono = datos[0].informacion.split(',')[3].trim();
                    console.log(datos[0].informacion);
                    var descripcion = datos[0].informacion.split(',')[4].replaceAll('_', ' ').replaceAll('.', ',').trim().slice(0, -1); //el slice quita el punto y coma del final

                    var estudios = [];
                    var trabajos = [];
                    var hobbies = [];

                    //recorremos todos los datos que tenemos
                    datos.forEach((dato, i) => {
                        //no comprobamos el objeto Informacion ya que se ha recorrido antes
                        if (i !== 0) {
                            if (dato.hobbies) {
                                var hobArr = dato.hobbies.split(';');
                                hobArr.pop();
                                hobArr.forEach(hob => {
                                    var modHob = hob.split(',')[1].trim().replaceAll('_', ' ');
                                    hobbies.push(modHob);
                                })
                            }
                            if (dato.estudios) {
                                var estArr = dato.estudios.split(';');
                                estArr.pop();
                                estArr.forEach(est => {
                                    var filtrado = est.split(',');
                                    const [id, titulo, centro, fechaInicio, fechaFin] = filtrado;
                                    estudios.push({ id: id, titulo: titulo.replaceAll('_', ' ').trim(), centro: centro.replaceAll('_', ' ').trim(), fechaInicio: fechaInicio.trim(), fechaFin: fechaFin.trim() });
                                })
                            }
                            if (dato.trabajos) {
                                var traArr = dato.trabajos.split(';');
                                traArr.pop();
                                traArr.forEach(tra => {
                                    var filtrado = tra.split(',');
                                    const [id, puesto, empresa, añoInicio, añoFin] = filtrado;
                                    trabajos.push({ id: id, puesto: puesto.replaceAll('_', ' ').trim(), empresa: empresa.replaceAll('_', ' ').trim(), añoInicio: añoInicio.trim(), añoFin: añoFin.trim() });
                                })
                            }

                        }
                    });

                    let htmlContent = `
                        <main style="padding: 30px;">
                        <h1>Datos Personales:</h1>
                        <hr style="margin-bottom:20px;"/>
                        <div style="margin:20px;display:flex;gap:20px;">
                            <img src="${imagen}" alt="Imagen de pefil" style="border-radius:50px;max-width:100px;max-height:100px;"/>
                            <div>
                                <p><b>Nombre:</b> ${nombre}</p>
                                <p><b>Fecha de nacimiento:</b> ${fecNac}</p>
                                <p><b>Correo electrónico:</b> ${correo}</p>
                                <p><b>Teléfono:</b> ${telefono}</p>
                                <p><b>Carta de presentación:</b> ${descripcion}</p>
                            </div>
                        </div>

                        <h2 style="margin:5px 0 5px 20px;">Hobbies:</h2>
                        <hr style="margin-bottom:20px;"/>
                        <ul style="list-style:none;margin-left:50px;">
                    `;
                    hobbies.forEach(hob => {
                        htmlContent += `<li>${hob}</li>`;
                    });
                    htmlContent += `</ul>`;

                    if (estudios.length > 0) {
                        htmlContent += `
                            <h2 style="margin:5px 0 5px 20px;">Estudios:</h2>
                            <hr style="margin-bottom:20px;"/>
                            <table border="1" style="width:100%;border-collapse:collapse;">
                                <tr>
                                    <th style="padding:10px;">Titulo</th>
                                    <th style="padding:10px;">Centro de estudios</th>
                                    <th style="padding:10px;">Fecha de inicio</th>
                                    <th style="padding:10px;">Fecha de fin</th>
                                </tr>
                        `;
                        estudios.forEach(est => {
                            htmlContent += `
                                <tr style="margin: 5px 0;">
                                    <td style="padding:10px;">${est.titulo}</td>
                                    <td style="padding:10px;">${est.centro}</td>
                                    <td style="padding:10px;">${est.fechaInicio}</td>
                                    <td style="padding:10px;">${est.fechaFin}</td>
                                </tr>
                            `;
                        });
                        htmlContent += `</table>`;
                    }

                    if (trabajos.length > 0) {
                        htmlContent += `
                            <h2 style="margin:5px 0 5px 20px;">Trabajos:</h2>
                            <hr style="margin-bottom:20px;"/>
                            <table border="1" style="width:100%;border-collapse:collapse;">
                                <tr>
                                    <th style="padding:10px;">Puesto</th>
                                    <th style="padding:10px;">Empresa</th>
                                    <th style="padding:10px;">Año de inicio</th>
                                    <th style="padding:10px;">Año de fin</th>
                                </tr>
                        `;
                        trabajos.forEach(tra => {
                            htmlContent += `
                                <tr style="margin: 5px 0;">
                                    <td style="padding:10px;">${tra.puesto}</td>
                                    <td style="padding:10px;">${tra.empresa}</td>
                                    <td style="padding:10px;">${tra.añoInicio}</td>
                                    <td style="padding:10px;">${tra.añoFin}</td>
                                </tr>
                            `;
                        });
                        htmlContent += `</table>`;
                    }
                    htmlContent += `</main>`;

                    // Opciones de configuración para html2pdf
                    const options = {
                        filename: 'CV.pdf',
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
                    };

                    // Generar el PDF
                    html2pdf().from(htmlContent).set(options).save();
                }

            })
        } catch (error) {
            console.error(error);
        }
    };

    const deletePortfolio = (e) => {
        try {
            var data = {
                id_portfolio: JSON.parse(localStorage.getItem('id_portfolio'))
            }

            var config = {
                'Content-Type': 'application/json'
            }

            axios.post('http://'+env.SERVER_HOST+':5000/API/deletePortfolio.php', {
                params: data,
                headers: config
            }).then(response => {
                if (response.data.status === 'success') {
                    window.location.reload();
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    const mostrarSuccess = () => {
        const modalContent = `
            <div class="modal-bg">
                <div class="modal">
                    <h1>Borrado con éxito</h1>
                    <i class="bi bi-check-circle-fill success"></i>
                </div>
            </div>
        `;

        const containerMain = document.querySelector('.container-main');
        if (containerMain.contains(document.getElementsByClassName('modal-bg')[0])) {
            containerMain.removeChild(document.getElementsByClassName('modal-bg')[0]);
        }
        containerMain.insertAdjacentHTML('beforeend', modalContent);
    }

    const mostrarModal = (e) => {
        var id_portfolio = e.target.id;
        const modalContent = `
            <div class="modal-bg">
                <div class="modal">
                    <h1>Atención</h1>
                    <i class="bi bi-exclamation-triangle"></i>
                    <span>Estás a punto de borrar un portfolio</span>
                    <span>¿Seguro que quieres continuar?</span>
                    <div>
                        <button id="btn-si">SI</button>
                        <button id="btn-no">NO</button>
                    </div>
                </div>
            </div>
        `;
        const containerMain = document.querySelector('.container-main');
        containerMain.insertAdjacentHTML('beforeend', modalContent);

        const btnSi = document.getElementById("btn-si");
        const btnNo = document.getElementById("btn-no");

        btnSi.addEventListener("click", () => {
            deletePortfolio(id_portfolio);
            setTimeout(mostrarSuccess(), 4000);
        });
        btnNo.addEventListener("click", () => {
            const modalBg = document.querySelector('.modal-bg');
            modalBg.remove();
        });
    };

    const updatePortfolio = (e) => {
        try {
            localStorage.setItem('id_portfolio', e.target.id);

            var data = {
                id_portfolio: JSON.parse(localStorage.getItem('id_portfolio'))
            }

            var config = {
                'Content-Type': 'application/json'
            }

            axios.post('http://'+env.SERVER_HOST+':5000/API/getInfoPortfolio.php', {
                params: data,
                headers: config
            }).then(response => {
                if (response.data.status === 'success') {
                    var datos = response.data.data;

                    console.log(datos);
                    console.log(datos[0].informacion);
                    var nombre = datos[0].informacion.split(',')[0].trim();
                    console.log(nombre);
                    var fecNac = datos[0].informacion.split(',')[1].trim();
                    console.log(fecNac);
                    var correo = datos[0].informacion.split(',')[2].trim();
                    console.log(correo);
                    var telefono = datos[0].informacion.split(',')[3].trim();
                    console.log(telefono);
                    var descripcion = datos[0].informacion.split(',')[4].replaceAll('_', ' ').replaceAll('.', ',').trim().slice(0, -1);
                    console.log(descripcion);

                    var estudios = [];
                    var trabajos = [];
                    var hobbies = [];

                    //recorremos todos los datos que tenemos
                    datos.forEach((dato, i) => {
                        //no comprobamos el objeto Informacion ya que se ha recorrido antes
                        if (i !== 0) {
                            if (dato.hobbies) {
                                var hobArr = dato.hobbies.split(';');
                                hobArr.pop();
                                console.log(hobArr);
                                hobArr.forEach(hob => {
                                    var filtrado = hob.split(',');
                                    const [id, descripcion, id_infoPer] = filtrado;
                                    console.log(id);
                                    console.log(descripcion);
                                    console.log(id_infoPer);
                                    hobbies.push({ id_hobUsu: id.trim(), descripcion: descripcion.replaceAll('_', ' ').trim(), id_infoPer: id_infoPer.trim() });
                                    console.log(hobbies);
                                })
                            }
                            if (dato.estudios) {
                                var estArr = dato.estudios.split(';');
                                estArr.pop();
                                estArr.forEach(est => {
                                    var filtrado = est.split(',');
                                    const [id, titulo, centro, fechaInicio, fechaFin] = filtrado;
                                    estudios.push({ id: id, titulo: titulo.replaceAll('_', ' ').trim(), centro: centro.replaceAll('_', ' ').trim(), fechaInicio: fechaInicio.trim(), fechaFin: fechaFin.trim() });
                                })
                            }
                            if (dato.trabajos) {
                                var traArr = dato.trabajos.split(';');
                                traArr.pop();
                                traArr.forEach(tra => {
                                    var filtrado = tra.split(',');
                                    const [id, puesto, empresa, añoInicio, añoFin] = filtrado;
                                    trabajos.push({ id: id, puesto: puesto.replaceAll('_', ' ').trim(), empresa: empresa.replaceAll('_', ' ').trim(), añoInicio: añoInicio.trim(), añoFin: añoFin.trim() });
                                })
                            }

                        }
                    });

                    var array = { 'nombre': nombre, 'hobbies': hobbies, 'fecNac': fecNac, 'correo': correo, 'telefono': telefono, 'descripcion': descripcion, 'estudios': estudios, 'trabajos': trabajos };
                    localStorage.setItem('infoPortfolio', JSON.stringify(array));
                    localStorage.setItem('editar', true); //semaforo para saber si está editando
                    window.location.href = '/anadir';
                }

            })
        } catch (error) {
            console.error(error);
        }
    }

    return localStorage.getItem('infousu') ? (
        <div className="container-main">
            <div className="main">
                <h1 className="hide">Editar</h1>
                <h1>Editar plantillas</h1>

                <div className="portfolio-container" onContextMenu={(e) => handleContextMenu(e)}>
                    {
                        portfolio.length > 0 ? portfolio.map((div, index) => {
                            /* console.log(div); */
                            return (
                                <div key={index} id={div?.id_portfolio} className="portfolio-div">{div?.nombre}</div>
                            )
                        }) : (<span>Aun no hay portfolios que mostrar</span>)
                    }
                </div>


                <div className="action-btns-form">
                    <a href="/menu-principal"><span><i className="bi bi-chevron-left"></i>Volver</span></a>
                </div>
            </div>
        </div>
    ) : (
        window.location.href = '/sesion'
    )
}

export default Editar;