import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../env";

function Sesion() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [pass, setPass] = useState('');

    useEffect(() => {
        const h1Text = document.querySelector('h1.hide').innerText;
        document.title = 'My personal portfolio - ' + h1Text;;

        //borra todos los datos del localstorage cuando inicia la aplicacion
        if (localStorage) {
            localStorage.clear();
        }

        const toggleCheckbox = JSON.parse(localStorage.getItem('checked'));
        if (toggleCheckbox) {
            document.querySelector('#logIn-form').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkSubContainerColor;
            document.querySelector('.main-form').style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkContainerColor;
            document.querySelectorAll('input').forEach(input => {
                input.style.backgroundColor = JSON.parse(localStorage.getItem('modoOscuro')).darkPElement;
            })
        } else {
            document.querySelector('#logIn-form').style.backgroundColor = 'var(--container-color)';
            document.querySelector('.main-form').style.backgroundColor = 'var(--bg-container-color)';
            document.querySelectorAll('input').forEach(input => {
                input.style.backgroundColor = 'var(--input-color)';
            })
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!(usuario && pass)) {
            if (usuario === '') {
                document.querySelector('#id_err_usu').classList.remove('error-inactivo');
                document.querySelector('#id_err_usu').parentNode.querySelector('input').style.border = '2px solid red';
            } else {
                document.querySelector('#id_err_usu').classList.add('error-inactivo');
                document.querySelector('#id_err_usu').parentNode.querySelector('input').removeAttribute('style');
            }

            if (pass === '') {
                document.querySelector('#id_err_pass').classList.remove('error-inactivo');
                document.querySelector('#id_err_pass').parentNode.querySelector('input').style.border = '2px solid red';
            } else {
                document.querySelector('#id_err_pass').classList.add('error-inactivo');
                document.querySelector('#id_err_pass').parentNode.querySelector('input').removeAttribute('style');
            }
        } else {
            var data = {
                usuario: encodeURIComponent(usuario),
                pass: encodeURIComponent(pass)
            };

            var config = {
                'Content-Type': 'application/json'
            }

            axios.post('http://'+env.SERVER_HOST+':5000/API/getUsuarios.php', {
                params: data,
                headers: config
            }).then(response => {
                console.log(response.data.status);

                if (response.data.status === 'success') {
                    delete response.data.user.pass; //borra la password del objeto
                    localStorage.setItem('infousu', JSON.stringify(response.data.user));

                    //si se ha insertado correctamente el usuario en el localstorage se redirige a otra pagina
                    if (localStorage.getItem('infousu')) {
                        window.location.href = '/menu-principal';
                    }
                } else {
                    alert(response.data.status);
                }
            }).catch(error => {
                console.error(error);
                alert('Usuario no encontrado');
            })
        }
    };

    const mostrar = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="main-form">
            <h1 className="hide">Inicio de sesion</h1>
            <form id="logIn-form" onSubmit={handleSubmit}>
                <h1>INICIA SESION</h1>
                <br />
                <div className="input-group">
                    <label htmlFor="Usuario">Usuario:</label>
                    <input
                        type="text"
                        name="usuario"
                        id="id_usuario"
                        placeholder="Nombre de usuario"
                        value={usuario}
                        onChange={(e) => setUsuario(e.target.value)}
                    />
                    <p id="id_err_usu" className="error error-inactivo"><i className="bi bi-exclamation-circle-fill"></i> Usuario incorrecto</p>
                </div>
                <div className="input-group wrap-input">
                    <label htmlFor="password">Password:</label>
                    <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        id="id_password"
                        placeholder="Password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                    />
                    <i
                        className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'}`}
                        id='ojo'
                        onClick={mostrar}
                    ></i>
                    <p id="id_err_pass" className="error error-inactivo"><i className="bi bi-exclamation-circle-fill"></i> Contraseña incorrecta</p>
                </div>
                <br />
                <div className="form-footer">
                    <p><b>¿Aún no te has registrado?</b></p>
                    <a href="/registro">Registrarse</a>
                    <br />
                    <input type="submit" name="enviar" id="id_enviar" value='INICIAR SESIÓN' />
                </div>
            </form>
        </div>
    );
}

export default Sesion;